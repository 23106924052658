<template>
    <v-dialog
        class="app-modal"
        v-model="show"
        persistent
        max-width="1300"
    >
      <v-card class="app-modal__card">
            <div class="app-modal__card--close">
               <inline-svg @click.stop="close()" src="/img/modal-close.svg" height="24" width="24" fill="#2e4dd4"/>
            </div>
            <v-form ref="form">
                <div class="app-modal__card--content">
                    <h1 class="app-modal__title">{{title}}</h1>
                    <div class="app-modal__plan">
                       <button-toggle @activeToggle="visiblePlanType = $event"/>
                       <v-item-group 
                            class="app-modal__plan-items"
                            v-model="selectedPlan"
                            v-if="visiblePlanType === 1"
                        >
                            <div v-for="plan in individualPlans" :key="plan.id">
                                <v-item 
                                    v-slot="{ active, toggle }"
                                    :value="plan.id"
                                    ripple
                                >
                                    <div 
                                        :class="active ? 'app-modal__plan-items-plan--active' : ''"
                                        class="app-modal__plan-items-plan"
                                        @click="toggle"
                                        ripple
                                    >
                                        <plan-card :data="plan"/>
                                    </div>
                                </v-item>
                            </div>
                        </v-item-group>
                        <v-item-group 
                            class="app-modal__plan-items"
                            v-model="selectedPlan"
                            v-if="visiblePlanType === 2"
                        >
                            <div v-for="plan in organisationPlans" :key="plan.id">
                                <v-item 
                                    v-slot="{ active, toggle }"
                                    :value="plan.id"
                                    ripple
                                >
                                    <div 
                                        :class="active ? 'app-modal__plan-items-plan--active' : ''"
                                        class="app-modal__plan-items-plan"
                                        @click="toggle"
                                        ripple
                                    >
                                        <plan-card :data="plan"/>
                                    </div>
                                </v-item>
                            </div>
                        </v-item-group>
                    </div>
                </div>
            </v-form>
            <div class="app-modal__card--actions">
                <v-btn class="app-modal__btn" elevation="4" rounded color="#fff" @click.stop="putPass">
                    <inline-svg :src="'/img/'+btnIcon+'.svg'" :height="btnIconSize" :width="btnIconSize" fill="#2e4dd4"/>
                    <span>{{btnTitle}}</span>
                </v-btn>
            </div>
      </v-card>
    </v-dialog>
</template>
<script>
import ButtonToggle from '../atoms/ButtonToggle.vue';
import PlanCard from '../molecules/PlanCard.vue';
import InlineSvg from 'vue-inline-svg';
export default {
    props:['title','btnIcon', 'btnIconSize','btnTitle','value','selectedItem'],
    components:{
        InlineSvg,
        ButtonToggle,
        PlanCard
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        individualPlans: function() {
            return this.plans.filter((plan)=>plan.type===1);
        },
        organisationPlans: function() {
            return this.plans.filter((plan)=>plan.type===2);
        }
    },
    data() {
        return {
            visiblePlanType:null,
            selectedPlan:null,
            plans:[
                {   
                    id:1,
                    type:1,
                    name: 'Demo',
                    users: 1,
                    folders: 1,
                    access: 1,
                    editing:true,
                    text:false,
                    logo:false,
                    price: null,
                    active:true,
                },
                {   
                    id:2,
                    type:1,
                    name: 'Core',
                    users: 2,
                    folders: 10,
                    access: 2,
                    editing:true,
                    text:false,
                    logo:false,
                    price: 9.99,
                    active:false,
                },
                {   
                    id:3,
                    type:1,
                    name: 'Pro',
                    users: 5,
                    folders: null,
                    access: 2,
                    editing:true,
                    text:true,
                    logo:true,
                    price: 19.99,
                    active:false,
                },
                {   
                    id:4,
                    type:2,
                    name: 'Basic',
                    users: 10,
                    folders: 3,
                    access: 2,
                    editing:true,
                    text:false,
                    logo:false,
                    price: 24.99,
                    active:false,
                },
                {   
                    id:5,
                    type:2,
                    name: 'Normal',
                    users: 25,
                    folders: 15,
                    access: 2,
                    editing:true,
                    text:true,
                    logo:false,
                    price: 29.99,
                    active:false,
                },
                {   
                    id:6,
                    type:2,
                    name: 'Extra',
                    users: 50,
                    folders: null,
                    access: 2,
                    editing:true,
                    text:true,
                    logo:true,
                    price: 39.99,
                    active:false,
                },
            ]
        }
    },
    mounted(){
    },
    methods: {
        close(){
           this.show = false;
        }, 
    }

}
</script>