<template>
    <div class="nav">
        <div class="nav__logo">
            <v-img src="/img/logo.png" width="61" height="47"/>
        </div>
        <div class="nav__user">
            <v-divider class="vertical-divider" vertical inset/>
            <v-avatar color="#2E4DD4" size="36">
                <img style="object-fit:cover" v-auth-image="$axios.defaults.baseURL+this.getUser.image"/>
            </v-avatar>
            <v-menu 
                offset-y 
                offset-x 
                nudge-bottom="28" 
                nudge-right="10" 
                transition="slide-y-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon  
                        v-bind="attrs"
                        v-on="on"
                    >
                        mdi-chevron-down
                    </v-icon>
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item-title class="cursor-pointer" @click="clickLogoutAccount">Schimbare Cont</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title class="cursor-pointer" @click="clickLogout">Delogare Admin</v-list-item-title>
                    </v-list-item>
                </v-list>
                </v-menu>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    data() {
        return {
            toggleFolderCrud:false,
        }
    },
    computed: mapGetters(["getUser"]),
    methods: {
        ...mapActions(['logout','logoutAccount']),
        clickLogout(){
            this.logout()
            .then(() => {
                this.$router.push('/login');
            });
        },
        clickLogoutAccount(){
            this.logoutAccount()
                .then(()=>{
                    this.$router.push('/account');
                })
        }
    }
}
</script>
