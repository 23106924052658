<template>
    <div v-if="this.users">
        <div class="account-details">
            <div class="account-details__details">
                <p class="account-details__details--title">Useri</p>
            </div>
            <div class="account-details__actions">
                <v-btn 
                    @click.stop="openAddModal = true" 
                    class="chartarium-button-hollow chartarium-button-hollow--primary" 
                    rounded 
                    elevation="0" 
                    color="#fff"
                >
                    <span>Adauga</span>
                    <v-icon>
                        mdi-plus
                    </v-icon>
                </v-btn>
            </div>
        </div>
        <crud-table :data='users' :fields='userFields' :canEdit="true" :canDelete="true"  @edit="editModal" @delete="deleteModal"/>
        <user-modal
            v-model="openAddModal" 
            :btnIcon="'save'" 
            :btnIconSize="24" 
            :title="'Adauga User'" 
            :btnTitle="'Salveaza'" 
            :roles="this.roles"
            @fetchUsers="$emit('refreshUsers')"
        />
        <user-edit-modal
            v-model="openEditModal" 
            :btnIcon="'save'" 
            :btnIconSize="24" 
            :title="'Editare User'" 
            :btnTitle="'Salveaza'" 
            :selectedItem="selectedItem"
            :roles="this.roles"
            @fetchUsers="$emit('refreshUsers')"
        />
        <delete-modal
            v-model="openDeleteModal" 
            :btnIcon="'modal-close'"
            :btnIconSize="24" 
            :title="'Exclude User'" 
            :btnTitle="'Exclude'" 
            :selectedItem="selectedItem"
            :isUser="true"
            @deleteUser="$emit('refreshUsers')"
        />
    </div>
</template>
<script>
import CrudTable from '../atoms/CrudTable.vue';
import UserModal from '../molecules/UserModal.vue';
import UserEditModal from '../molecules/UserEditModal.vue';
import DeleteModal from '../molecules/DeleteModal.vue';
export default {
    components: {
        CrudTable,
        UserModal,
        UserEditModal,
        DeleteModal
    },
    props:['users'],
    data(){
        return{
            roles:null,
            openAddModal:false,
            openEditModal:false,
            openDeleteModal:false,
            selectedItem:null,
            userFields: [
                {
                    label:'Nr. Crt.',
                    getValue: item =>item.id,
                    key:'id',
                    ref:'id'
                },
                {
                    label:'Nume User',
                    getValue: item =>item.name,
                    key:'name',
                    ref:'name'
                },
                {
                    label:'Email',
                    getValue: item =>item.email,
                    key:'email',
                    ref:'email'
                },
                {
                    label:'Rol',
                    getValue: item =>item.roles[0].name,
                    key:'role',
                    ref:'role'
                },
            ]
        }
    },
    mounted(){
        this.getRoles();
    },
    methods: {
        getRoles(){
            this.$axios.get('/accounts/'+this.$route.params.account_id+'/roles')
                .then(res=>{
                    this.roles = res.data
                })
        },
        editModal($event){
            this.openEditModal = true;
            this.selectedItem = JSON.parse(JSON.stringify($event));
        },
        deleteModal($event){
            this.openDeleteModal = true;
            this.selectedItem = $event;
        }
    }
}
</script>