<template>
  <div class="h-full">
    <navbar/>
    <div class="main" v-if="this.user && this.account && this.users">
        <!-- Account Details -->
        <account-details :data="this.user" @refreshUser="refreshData"/>
        <div class="horizontal-divider"/>

        <!-- Personalize account -->
        <account-customize :data="this.account" @refreshAccount="getAccount"/>
        <div class="horizontal-divider"/>

        <!-- Payment Methods -->
        <payment-methods />
        <div class="horizontal-divider"/>
        <!-- Users -->
        <users :users="this.users" @refreshUsers="getUsers"/>
        <div class="horizontal-divider"/>

        <!-- Roles -->

        <roles/>

    </div>
  </div>
</template> 

<script>
import Navbar from "../components/organisms/Navbar.vue";
import AccountDetails from "../components/organisms/AccountDetails.vue";
import AccountCustomize from "../components/organisms/AccountCustomize.vue";
import Users from "../components/organisms/Users.vue";
import Roles from "../components/organisms/Roles.vue";
import PaymentMethods from "../components/organisms/PaymentMethods.vue";

export default {
  components: {
    Navbar,
    AccountDetails,
    AccountCustomize,
    Users,
    Roles,
    PaymentMethods
  },
  data(){
    return {
      user:null,
      users:null,
      account:null,
    }
  },
  mounted(){
    this.getUser();
    this.getUsers();
    this.getAccount();
  },
  methods:{
    getUser(){
      this.$axios.get('auth/me')
        .then(res=>{
          this.user = res.data;
        })
    },    
    getAccount() {
      this.$axios.get('/accounts/'+this.$route.params.account_id)
        .then(res=>{
          this.account = res.data;
        })
    },
    getUsers() {
        this.$axios.get('/accounts/'+this.$route.params.account_id+'/users')
            .then(res=>{
                this.users = res.data
            })
    },
    refreshData(){
      this.getUser();
      this.getUsers();
    }
  }
}
</script>

