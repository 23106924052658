<template>
    <div>
        <div class="account-details">
            <div class="account-details__details">
                <p class="account-details__details--title">Metoda de plata</p>
            </div>
            <div class="account-details__actions">
                <v-btn @click.stop="openAddModal = true" class="chartarium-button-hollow chartarium-button-hollow--primary" rounded elevation="0" color="#fff">
                    <span>Adauga</span>
                    <v-icon>
                        mdi-plus
                    </v-icon>
                </v-btn>
            </div>
        </div>
        <div class="methods" v-if="paymentMethods">
            <div v-if="paymentMethods.length != 0" class="method" v-for="method in paymentMethods" :key="method.id">
                <v-icon>mdi-credit-card</v-icon>
                <div class="brand">{{ method.card.brand }}</div>
                <div class="last-four">**** **** **** {{ method.card.last4 }}</div>
                <div class="expires" @click="selectForDelete(method)">Exp: {{ method.card.exp_month }} / {{ method.card.exp_year }}<div class="remove">STERGE CARD</div></div>
            </div>
            <div class="no-card" v-if="paymentMethods.length == 0">
                Nici o metoda de plata atasata.
            </div>
        </div>
        <v-progress-circular v-else
              indeterminate
              color="secondary"
            ></v-progress-circular>
        
        <div class="modals">

            <delete-payment-method-modal
                v-if="selectedItem"
                v-model="openDeleteModal" 
                :btnIcon="'modal-close'"
                :btnIconSize="24" 
                :title="'Sterge Card'" 
                :btnTitle="'Sterge'" 
                :selectedItem="selectedItem" 
                @deleteUser="getPaymentMethods"
            />


            <v-dialog class="app-modal" v-model="openAddModal" persistent max-width="620">
                <v-card class="app-modal__card">
                    <div class="app-modal__card--close">
                        <inline-svg @click.stop="openAddModal = false" src="/img/modal-close.svg" height="24" width="24" fill="#2e4dd4" />
                    </div>
                    <v-form ref="form">
                        <div class="app-modal__card--content">
                            <h1 class="app-modal__title">Adauga Card</h1>
                            <div class="app-modal__card--folder-title">
                                <div class="stripe" v-if="paymentIntent">
                                    <!-- <v-text-field class="card-holder-name" v-model="cardHolderName" dense placeholder="Numele de pe card" :rules="[() => !!cardHolderName || 'Campul este obligatoriu']"></v-text-field> -->
                                    <div id="card-element">
                                    </div><br>
                                    <v-btn id="card-button" @click.stop="updatePaymentMethod" class="chartarium-button-hollow chartarium-button-hollow--primary" rounded elevation="0" color="#fff">
                                        Salveaza
                                    </v-btn><br><br>
                                </div>
                            </div>
                        </div>
                    </v-form>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>
<style scoped lang="scss">
.stripe {
    width: 350px;
}

.methods {
    width:  350px;
}

.method {

    margin-bottom: 10px;
    border:  2px solid #ececec;
    border-radius:  8px;
    padding:  5px;

    .brand {
        display: inline-block;
        text-transform: uppercase;
        font-weight: 600;
        margin-left: 10px;
    }

    .last-four {
        font-weight: 600;;
        display: inline-block;
        float: right;
    }

    .remove {
        display: none;
        float: right;
        color: #c62828;
        font-weight: 600;;
    }

    .expires {
        font-weight: 600;;
    }
    
    &:hover{
        .remove {
            cursor: pointer;
            display: inline-block;
        }
    }
}

.card-holder-name {
    margin-bottom: 15px;
    font-size: 18px;
    outline: none;
}

#card-holder-name::placeholder {
    color: #32325d;
}

#card-element {
    width: 350px;
    margin-bottom: 15px;
}

#card-button {
    width: 350px;

}
</style>
<script>
import InlineSvg from 'vue-inline-svg';
import DeletePaymentMethodModal from '../molecules/DeletePaymentMethodModal.vue';
export default {
    components:{
        'inline-svg':InlineSvg,
        DeletePaymentMethodModal
    },
    data() {
        return {

            // Methods
            paymentMethods: null,


            // New Intent
            openAddModal: false,
            openDeleteModal: false,
            selectedItem: null,
            cardHolderName: null,
            paymentIntent: null,
            stripe: window.Stripe('pk_test_51KlFMSIQdQl7x0VcrVSfJ06LBNitLoFjNWwyuuc80DXtSWmGFRGSbqfJkhx9PygQQSoBnXyXDliKS5Gc06UtjtUr009E0QZ48G'),
            elements: null,
            cardElement: null,
        }
    },
    async mounted() {

        await this.getPaymentMethods();
        await this.getPaymentIntent();

        this.openAddModal = true;

        await this.$nextTick();

        this.elements = this.stripe.elements();
        this.cardElement = await this.elements.create('card', {
            hidePostalCode: true,
            style: {
                base: {
                    color: "#32325d",
                    fontSize: "18px"
                }
            }
        });
        this.cardElement.mount("#card-element");

        this.openAddModal = false;
        

    },
    methods: {

        selectForDelete(method) {   
            this.selectedItem = method;
            this.openDeleteModal = true;
            console.log('x');
        },

        async getPaymentMethods() {

            try {
                this.paymentMethods = null;
                let response = await this.$axios.get('auth/getPaymentMethods');
                this.paymentMethods = response.data;
            } catch (error) {
                console.log(error);
            }

        },

        async getPaymentIntent() {
            try {
                let response = await this.$axios.get('auth/getSetupIntent');
                this.paymentIntent = response.data.intent;
            } catch (error) {
                console.log(error);
            }
        },
        async updatePaymentMethod() {

            const { setupIntent, error } = await this.stripe.confirmCardSetup(
                this.paymentIntent.client_secret, {
                    payment_method: {
                        card: this.cardElement,
                        billing_details: { name: this.cardHolderName }
                    }
                }
            );

            if (error) {
                // handle stripe card errors ?
                console.log(error);
                return;
            }

            try {

                let response = await this.$axios.post('auth/updatePaymentMethod', {
                    payment_method: setupIntent.payment_method,
                });

                this.openAddModal = false;
                this.getPaymentMethods();


            } catch (error) {
                console.log(error);
            }


            console.log(setupIntent.payment_method);

        },
    }
}
</script>