<template>
    <div class="chartarium-plan">
        <p class="chartarium-plan__title">{{data.name}}</p>

        <table class="chartarium-plan__details">
            <tbody>
                <tr>
                    <td>Useri:</td>
                    <td>{{data.users}}</td>
                </tr>
                <tr>
                    <td>Foldere Create:</td>
                    <td v-if="data.folders !== null">{{data.folders}}</td>
                    <td v-if="data.folders === null">Nelimitat</td>
                </tr>
                <tr>
                    <td>Access Resurse:</td>
                    <td v-if="data.access === 1">Limitat</td>
                    <td v-if="data.access === 2">Total</td>
                </tr>
                <tr>
                    <td>Editare Resurse:</td>
                    <td>
                        <inline-svg v-if ="data.editing" :src="'/img/check.svg'" height="14" width="14" fill="#00B981"/>
                        <inline-svg v-else :src="'/img/close-modal.svg'" height="14" width="14" fill="#F21B3F"/>
                    </td>
                </tr>
                <tr :class="{'chartarium-plan__details--hidden': !data.text}">
                    <td>Personalizare cu text:</td>
                    <td>
                        <inline-svg v-if ="data.text" :src="'/img/check.svg'" height="14" width="14" fill="#00B981"/>
                        <inline-svg v-else :src="'/img/close-modal.svg'" height="14" width="14" fill="#F21B3F"/>
                    </td>
                </tr>
                <tr :class="{'chartarium-plan__details--hidden': !data.logo}">
                    <td>Personalizare cu logo:</td>
                    <td>
                        <inline-svg v-if ="data.logo" :src="'/img/check.svg'" height="14" width="14" fill="#00B981"/>
                        <inline-svg v-else :src="'/img/close-modal.svg'" height="14" width="14" fill="#F21B3F"/>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="horizontal-dotted-divider"></div>

        <p v-if="data.price===null" class="chartarium-plan__price">
            Gratis
        </p>
        <p v-if="data.price!==null" class="chartarium-plan__price">
            {{data.price}} RON<span>/luna</span>
        </p>
    </div>
</template>
<script>
import InlineSvg from 'vue-inline-svg';
export default {
    props:['data'],
    components:{
        'inline-svg':InlineSvg
    },
    data(){
        return {

        }
    }
}
</script>