<template>
    <v-simple-table class="chartarium-table">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th v-for="(field,index) in fields" :key="index">
                            {{ field.label }}
                        </th>
                        <!-- Actions -->
                        <th v-if="canDelete || canEdit" style="width: 360px;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in data" :key="item.id"> 
                        <td v-for="(field,index) in fields" :key="index">

                            <div v-html='field.getValue(item)'></div>
                            
                        </td>
                        <!-- Actions -->
                        <td  v-if="canDelete || canEdit" style="width: 360px;">
                            <div class="chartarium-table__actions">
                                <v-btn v-if="canEdit" @click="$emit('edit', item)" class="chartarium-button-full chartarium-button-full--table" rounded color="#2E4DD4">
                                    Editeaza User
                                    <v-icon right dark>
                                        mdi-pencil-outline
                                    </v-icon>
                                </v-btn>
                                <v-btn v-if="canEdit" @click="$emit('delete', item)" class="chartarium-button-full chartarium-button-full--table" rounded color="#FFBC6E">
                                    Sterge User
                                    <v-icon right dark>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </div>
                        </td>
                    </tr>
                </tbody> 
            </template>
        </v-simple-table>
</template>
<script>
export default {
    props: ['fields', 'data', 'canEdit', 'canDelete'],
}
</script>